import axios from './axios'

export const getStationList = () =>
  axios
    .get('/station/ev-place')
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })

export const getStationByID = (id: string) =>
  axios
    .get(`/station/ev-place/${id}`)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })

export const getEVType = () =>
  axios
    .get('/station/ev-meter-type')
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })


export const createEVStation = (payload: any) =>
  axios
    .post('/station/ev', payload)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })

export const updateEVStation = (payload: any, id: string) =>
  axios
    .patch(`/station/ev/${id}`, payload)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })


export const deleteStation = (id: string) =>
  axios
    .delete(`/station/ev/${id}`)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })