import { Routes, Route, Navigate } from "react-router-dom";

import dayjs from "dayjs";

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { useEffect, useState } from "react";

// require("dayjs/locale/th");
// dayjs.locale("th");

const App = () => {
  const [isToken] = useState(localStorage.getItem("token"));
  useEffect(() => {
    console.log("isToken", !!isToken);
    if (!isToken && window.location.pathname.includes('admin')) {
      window.location.replace(`/auth/sign-in`);
    }
  }, [isToken]);

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="rtl/*" element={<RtlLayout />} />
      <Route path="/" element={<Navigate to="/admin" replace />} />
    </Routes>
  );
};

export default App;
