import TableCode from "./components/TableCode";
import { useCoinDetailQuery } from "redux/services/coin/coinApi";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

const ManagementCoin = () => {
  const params = useParams();
  const { data } = useCoinDetailQuery(params.id);
  const table = useMemo(() => {
    return data?.coins?.map((item: any, index: number) => ({
      index: index + 1,
      ...item,
    }));
  }, [data]);
  return (
    <div>
      <div className="flex w-full flex-col gap-5">
        <div className="md:grid-cols mt-5 grid h-full grid-cols-1 gap-5">
          {table && <TableCode tableData={table} name={data.name}/>}
        </div>
      </div>
    </div>
  );
};

export default ManagementCoin;
