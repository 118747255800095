import React from "react";
import Card from "components/card";
import dayjs from 'dayjs';
import "dayjs/locale/th";
import { MdOutlineEdit, MdOutlineRemoveRedEye, MdDeleteOutline, MdErrorOutline } from "react-icons/md";
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import ModalStation from "./ModalStation"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getPaginationRowModel
} from "@tanstack/react-table";
import {
  Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Flex, 
  useToast
} from "@chakra-ui/react";
import {
  deletePlace
} from 'services/place'

type RowObj = {
  id?: string;
  index?: number;
  stationName: string;
  googleMapLink?: string;
  evStations?: number;
  createdAt? : string;
  lat?: number;
  lng?: number;
};

function TablePlace(props: { tableData: any, reloadData: () => void, onEdit: any }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ isOpenStation, setOpenStation ] = React.useState(false)
  const [placeId, setPlaceId] = React.useState("")
  const [stationName, setStationName] = React.useState("")
  const [station, setStation] = React.useState(null)
  const cancelRef = React.useRef()
  const { tableData, reloadData, onEdit } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const convertDate = (date : string) =>{
    return dayjs(date).locale('th').format('DD MMM YYYY - HH:mm')
  }
  const handleRemove = (id: string) => {
    const stationName = data.find((val:any)=> val.id === id).stationName
    setStationName(stationName)
    setPlaceId(id)   
    onOpen()
  }
  const handleViewStation = (id: string) => {
    const station = data.find((val:any)=> val.id === id)
    setStation(station)
    setPlaceId(id)   
    setOpenStation(true)
  }
  
  const columns = [
    columnHelper.accessor("index", {
      id: "index",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">#</p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="ml-1 text-sm text-navy-700 dark:text-white">
            {info.getValue() + "."}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("stationName", {
      id: "stationName",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          ชื่อสถานีชาร์จ
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("googleMapLink", {
      id: "googleMapLink",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          สถานที่ตั้ง
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm text-navy-700 dark:text-white">
            <a href={info.getValue()} target="_blank" rel="noreferrer" className="text-blue-500 underline">{info.getValue()}</a>
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("evStations", {
      id: "evStations",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          จำนวนเครื่องชาร์จ
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="ml-3 text-sm text-navy-700 dark:text-white">
            {info.getValue().length || 0}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          วันที่สร้าง
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm text-navy-700 dark:text-white">
            {convertDate(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <div className="mr-4 flex items-center justify-center text-gray-600 dark:text-white gap-2">
            <div className="cursor-pointer" onClick={()=>{handleViewStation(info.getValue())}}> <MdOutlineRemoveRedEye size={20}/></div>
            <div className="cursor-pointer" onClick={()=>{onEdit(info.getValue())}}> <MdOutlineEdit size={20}/></div>
            <div className="cursor-pointer" onClick={()=>{handleRemove(info.getValue())}}><MdDeleteOutline size={20}/></div>
          </div>
        </div>
      ),
    }),
  ];
  const data = tableData
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    autoResetAll: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  const confirmDelete = async () =>{
    const result = await deletePlace(placeId)
    if(result && result.status === 200){
      onClose()
      reloadData()
    }else{
      toast({
        position: 'top',
        title: 'Error',
        description: result.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      if(result.status === 401) {
        window.location.replace(`/auth/sign-in`);
      }
    }
   
  }

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl text-navy-700 dark:text-white">
        รายชื่อสถานีชาร์จ
        </div>
      </header>
      <div className="h-2" />
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 bg-[#F9FAFB] pb-2 pl-4 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px]  border-b-[1px] border-gray-200  py-3  pl-4 pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="flex items-center gap-2 justify-end mt-4 mb-4 text-sm">
          <span className="font-light">
                Rows per page:
          </span>
          <Select
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
            width={'80px'}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>

          <span className="flex items-center mx-3">


          {(table.getState().pagination.pageIndex * table.getState().pagination.pageSize) +1} - { (table.getState().pagination.pageIndex * table.getState().pagination.pageSize) + table.getRowModel()
                .rows.length } of {data.length}
          </span>

          <button
            className=" p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronDoubleLeftIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <button
            className="p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <button
            className="p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <button
            className="p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <ChevronDoubleRightIcon className="h-4 w-4" aria-hidden="true" />
          </button>
      </div>
      </div>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='x-large' fontWeight='normal' className="text-center">
              <div className="flex justify-center mb-2">
                <MdErrorOutline size={40} className="bg-red-100 rounded-full p-1 text-red-700"/>
                </div>
                ยืนยันลบสถานีชาร์จหรือไม่?
            </AlertDialogHeader>

            <AlertDialogBody className="text-center text-[#667085]">
              ยืนยันการลบสถานี {stationName} หรือไม่?
            </AlertDialogBody>

              <Flex gap={2} className="p-4">
                <Button onClick={onClose} ref={cancelRef} className="w-full" variant="outline">
                  <div className="font-normal">ยกเลิก</div>
                </Button>
                <Button colorScheme='red' onClick={()=>{confirmDelete()}} className="w-full">
                  <div className="font-normal">ลบ</div>
                </Button>
              </Flex>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {isOpenStation && <ModalStation station={station} isOpen={isOpenStation} onClose={()=>setOpenStation(false)}/> }
    </Card>
  );
}

export default TablePlace;
const columnHelper = createColumnHelper<RowObj>();
