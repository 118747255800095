import { useEffect, useRef, useState } from "react";
import Card from "components/card";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Grid,
  Flex, 
  Spacer,
  Box,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {
  createCoin,
  getCoinByid,
  updateCoin,
  deleteCode
} from 'services/coin'
import { MdDeleteOutline, MdAttachFile, MdDownload, MdOutlineOfflinePin, MdErrorOutline } from "react-icons/md";
import dayjs from 'dayjs'

const validate = (values: { status:string; name: string; value: number, startDate: string,endDate: string, files: any[] }) => {
  const errors:any = {};
  const NamePattern = /^[0-9a-zA-Zก-๙\s]+$/
  if (!values.name) {
    errors.name = 'กรุณากรอกข้อมูลให้ครบถ้วน';
  }
  if(values.name) {
    if(!NamePattern.test(values.name)){
      errors.name = 'กรุณากรอกข้อมูลให้ถูกต้อง';
    }
  }
  if (!values.value) {
    errors.value = 'กรุณากรอกข้อมูลให้ครบถ้วน';
  }
  if (!values.startDate) {
    errors.startDate = 'กรุณากรอกข้อมูลให้ครบถ้วน';
  }
  if (!values.endDate) {
    errors.endDate = 'กรุณากรอกข้อมูลให้ครบถ้วน';
  }
  if (values.files.length === 0) {
    errors.files = 'กรุณาอัพโหลดไฟล์';
  }
  return errors;
};

const FormCoin = () => {
  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const cancelRef = useRef()
  const [statusTemp, setStatusTemp] = useState('Unpublish');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteFileIndex, setDeleteFileIndex] = useState({index:null,name:''});
  const formik = useFormik({
    initialValues: {
      name:'',
      value: null,
      startDate: '',
      endDate: '',
      status: 'Unpublish',
      files: []
    },
    enableReinitialize: true,
    validate,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const getCoin = async (id: string) => {
    try {
      const result = await getCoinByid(id)
      if (result.status === 200 && result.data) {
        formik.setFieldValue('name', result.data.name);
        formik.setFieldValue('value', result.data.value);
        formik.setFieldValue('status', result.data.status);
        formik.setFieldValue('startDate',  dayjs(result.data.startDate).format('YYYY-MM-DD[T]HH:mm'));
        formik.setFieldValue('endDate',  dayjs(result.data.endDate).format('YYYY-MM-DD[T]HH:mm'));
        formik.setFieldValue('files', result.data.files);
      }else{
        formik.setFieldValue('status', 'Publish');
        toast({
          position: 'top',
          title: 'Error',
          description: 'Coin isInvalid',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (error) {
      formik.setFieldValue('status', 'Publish');
      toast({
        position: 'top',
        title: 'Error',
        description: 'Coin isInvalid',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if(params.id){
      getCoin(params.id)
    }
  }, [params.id])

  const handleSubmit = async (values: any) => {
    formik.setSubmitting(false);
    const payload = values
    payload.startDate = dayjs(payload.startDate).unix()
    payload.endDate = dayjs(payload.endDate).unix()
    const bodyFormData = new FormData();
    bodyFormData.append('name', payload.name.trim());
    bodyFormData.append('value', payload.value);
    bodyFormData.append('status', statusTemp);
    bodyFormData.append('startDate', payload.startDate);
    bodyFormData.append('endDate', payload.endDate);
    payload.files.forEach((file:any) => bodyFormData.append('files', file));
    if(params.id){
      const result = await updateCoin(bodyFormData,params.id)
      formik.setSubmitting(true);
      if(result && result.status === 200){
        navigate("/admin/coins");
      }else{
        toast({
          position: 'top',
          title: 'Error',
          description: result.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }else{
      const result = await createCoin(bodyFormData)
      formik.setSubmitting(true);
      if(result && result.status === 200){
        navigate("/admin/coins");
      }else{
        toast({
          position: 'top',
          title: 'Error',
          description: result.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } 
  }

  const exportExcel = () => {
    const ws = XLSX.utils.json_to_sheet([{"MAY30":"MAY30"},{"MAY30":"MAY30"},{"MAY30":"MAY30"},{"MAY30":"MAY30"},{"MAY30":"MAY30"},{"MAY30":"MAY30"},{"MAY30":"MAY30"},{"MAY30":"MAY30"},{"MAY30":"MAY30"},{"MAY30":"MAY30"}])
    const wb = { Sheets: {'Sheet 1': ws}, SheetNames: ['Sheet 1']}
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.ms-excel;charset=utf-8"
    });
    FileSaver.saveAs(blob, "coin_template.xlsx");
  }

  const openConfirm = (status:string) => {
    setStatusTemp(status)
    onOpen()
  }

  const confirmSubmit = () => {
    formik.handleSubmit()
    onClose()
  }

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0]
    if(file) {
      let arr = []
      if(formik.values.files){
        arr = [...formik.values.files,file]
      }else{
        arr = [file]
      }
      formik.setFieldValue('files', arr)
    }
  }

  const confirmDelete = async () => {
    const files = formik.values.files;
    const index = deleteFileIndex.index-1;
    if(files[index].id){
      const result = await deleteCode(files[index].id)
      if(result && result.status === 200){
        files.splice(index, 1);
        formik.setFieldValue('files', files)
      }else{
        toast({
          position: 'top',
          title: 'Error',
          description: result.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }else{
      files.splice(index, 1);
      formik.setFieldValue('files', files)
    }
    setDeleteFileIndex({index:null,name:''})
  }

  const removeCode = (index: number) => {
    const files = formik.values.files;
    setDeleteFileIndex({index:index+1,name:files[index].name})
  }

  const getFileName = (e: any) => {
    return e.name
  }
  const getFileSize= (e: any) => {
    if(e.size === 0) return '0 Bytes';
    const k = 1024,
        dm = 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(e.size) / Math.log(k));
    return parseFloat((e.size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const getCodeCount = (e: any) => {
    return e.numberOfRow ? <div className="text-sm text-green-500">รหัสที่นำเข้าแล้ว {e.numberOfRow} รายการ</div> : <div className="text-sm text-yellow-500">รหัสรอการนำเข้า</div>
  }
  
  return (
    <div>
      <div className="flex flex-row-reverse mt-2">
      </div>
      <div className="flex w-full flex-col gap-5">
        <div className="md:grid-cols mt-5 grid h-full grid-cols-1 gap-5">

          <Card extra={"w-full h-full sm:overflow-auto px-6"}>
            <header className="relative flex items-center justify-between pt-4">
              <div className="text-xl text-navy-700">
              เพิ่มรายละเอียดคอยน์
              </div>

              {/* <CardMenu /> */}
            </header>

            <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">

                  <form onSubmit={formik.handleSubmit}>
                      <FormControl isInvalid={formik.errors.name && formik.touched.name} >
                          <FormLabel htmlFor="name">ชื่อคอยน์ <span className="text-red-500">*</span></FormLabel>
                          <Input
                            value={formik.values.name}
                            id="name"
                            variant="outline"
                            placeholder="ระบุชื่อคอยน์"                            
                            onChange={formik.handleChange}
                            disabled={formik.values.status === "Publish" ? true : false}
                          />
                          <FormErrorMessage className="text-red-500 text-xs">{formik.errors.name}</FormErrorMessage>
                      </FormControl>
                      <FormControl className="mt-4" isInvalid={formik.errors.value && formik.touched.value}>
                          <FormLabel htmlFor="name">มูลค่าคอยน์ <span className="text-sm">(ส่วนลด)</span> <span className="text-red-500">*</span></FormLabel>
                          <Input
                            value={formik.values.value}
                            type="number"
                            id="value"
                            variant="outline"
                            placeholder="ระบุชื่อคอยน์"
                            onChange={formik.handleChange}
                            disabled={formik.values.status === "Publish" ? true : false}
                          />
                          <FormErrorMessage className="text-red-500 text-xs">{formik.errors.value}</FormErrorMessage>
                      </FormControl>
                      <div className="mt-5">
                        <h2 className="text-xl">ระยะเวลาใช้คอยน์</h2>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                          <FormControl className="mt-4" isInvalid={formik.errors.startDate && formik.touched.startDate}>
                            <FormLabel htmlFor="name">เริ่ม <span className="text-red-500">*</span></FormLabel>
                            <Input
                              value={formik.values.startDate}
                              placeholder="Select Date and Time"
                              id="startDate"
                              type="datetime-local"
                              onChange={formik.handleChange}
                              disabled={formik.values.status === "Publish" ? true : false}
                            />
                            <FormErrorMessage className="text-red-500 text-xs">{formik.errors.startDate}</FormErrorMessage>
                          </FormControl>
                          <FormControl className="mt-4" isInvalid={formik.errors.endDate && formik.touched.endDate}>
                            <FormLabel htmlFor="name">สิ้นสุด <span className="text-red-500">*</span></FormLabel>
                            <Input
                              value={formik.values.endDate}
                              placeholder="Select Date and Time"
                              id="endDate"
                              type="datetime-local"
                              onChange={formik.handleChange}
                              disabled={formik.values.status === "Publish" ? true : false}
                            />
                            <FormErrorMessage className="text-red-500 text-xs">{formik.errors.endDate}</FormErrorMessage>
                          </FormControl>
                        </Grid>
                      </div>
                      <div className="mt-5">
                        <h2 className="text-xl">รหัสสำหรับการใช้คอยน์</h2>
                        <FormControl className="mt-4" isInvalid={formik.errors.files && formik.touched.files ? true : false}>
                            <FormLabel htmlFor="files">
                            <Flex>
                              อัปโหลดไฟล์ <span className="text-red-500">*</span>
                              <Spacer />
                              <Button variant='outline' leftIcon={<MdDownload color="gray"/>} size={'sm'} onClick={()=>{exportExcel()}}>
                                <span className="text-gray-500">ดาวน์โหลดแบบฟอร์ม</span>
                              </Button>
                              </Flex>
                            </FormLabel>
                            <label htmlFor="files" className={`flex flex-col items-center justify-center w-full h-30 border-2  rounded-lg cursor-pointer bg-white hover:bg-gray-100 ${formik.errors.files && formik.touched.files ? 'border-red-500' : 'border-gray-200'}`}>
                              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg className="w-8 h-8 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                    </svg>
                                    <p className="mb-2 text-md text-black"><span className="font-semibold">คลิกเพื่ออัปโหลดไฟล์</span></p>
                                    <p className="text-xs text-gray-500">.XLSX (max. 10MB)</p>
                                </div>
                            <Input
                              id="files"
                              type="file"
                              accept=".xlsx"  
                              onChange={(e) => handleFileUpload(e)}
                              className="hidden"
                              disabled={formik.values.status === "Publish" ? true : false}
                            />                           
                            </label>
                            <FormErrorMessage className="text-red-500 text-xs">{formik.errors.files ? `${formik.errors.files}` : ''}</FormErrorMessage>
                        </FormControl>
                        {formik.values.files && formik.values.files.length > 0 ? (
                          formik.values.files.map((file, index) => (
                          <Flex key={index} className="mt-4 border-2 border-gray-200 rounded-lg" minWidth='max-content' gap='1'>
                            <Box p='2'>
                              <IconButton
                                isRound={true}
                                aria-label='Done'
                                size='sm'
                                icon={<MdAttachFile />}
                              />
                              
                            </Box>
                            <Box p='2'>
                              <div className="text-sm">{getFileName(file)}</div>
                              <div className="text-sm text-gray-500">{getFileSize(file)}</div>
                             {getCodeCount(file)}
                            </Box>
                            <Spacer />
                            {
                              formik.values.status === "Unpublish" &&                             
                              <div className="p-2 cursor-pointer"  onClick={() => {removeCode(index)}}>
                                <MdDeleteOutline size={20}/>
                              </div>}
                          </Flex>
                          ))
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <Flex className="mt-4 mb-6" gap={2}>
                        <Spacer/>
                        <Button
                          mt={4}
                          type='button'
                          variant='ghost'
                          colorScheme='gray'
                          onClick={()=>{ formik.values.status === "Unpublish" && openConfirm('Unpublish') }}
                          disabled
                        >
                          บันทึกแบบร่าง
                        </Button>
                        <Button
                          mt={4}
                          colorScheme='facebook'
                          type='button'
                          onClick={()=>{ formik.values.status === "Unpublish" && openConfirm('Publish') }}
                        >
                          เผยแพร่
                        </Button>
                      </Flex>
                  </form>

            </div>
          </Card>
        </div>
      </div>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='x-large' fontWeight='normal' className="text-center">
              <div className="flex justify-center mb-2">
                <MdOutlineOfflinePin size={40} className="bg-[#E0E5F2] rounded-full p-1"/>
                </div>
              {statusTemp === 'Publish' ? 'ยืนยันการเผยแพร่คอยน์?' : 'ยืนยันบันทึกร่างคอยน์?'}
            </AlertDialogHeader>

            <AlertDialogBody className="text-center text-[#667085]">
              ยืนยันการ{statusTemp === 'Publish' ? 'เผยแพร่' : 'บันทึกร่าง'}คอยน์ {formik.values.name || '-'} <br/>
              {statusTemp === 'Publish' ? 'ยืนยันเเล้วจะไม่สามารถแก้ไขรายละเอียดของคอยน์ได้' : 'ต้องการให้คอยน์ใช้งานได้กรุณากดปุ่ม "เผยแพร่"'}
            </AlertDialogBody>

              <Flex gap={2} className="p-4">
                <Button onClick={onClose} ref={cancelRef} className="w-full" variant="outline">
                  <div className="font-normal">ยกเลิก</div>
                </Button>
                <Button colorScheme='facebook' onClick={()=>{confirmSubmit()}} className="w-full">
                  <div className="font-normal">{statusTemp === 'Publish' ? 'เผยแพร่' : 'บันทึกร่าง'}</div>
                </Button>
              </Flex>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={deleteFileIndex.index ? true : false}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='x-large' fontWeight='normal' className="text-center">
              <div className="flex justify-center mb-2">
                <MdErrorOutline size={40} className="bg-red-100 rounded-full p-1 text-red-700"/>
                </div>
                ต้องการลบไฟล์
            </AlertDialogHeader>

            <AlertDialogBody className="text-center text-[#667085]">
              ยืนยันการลบไฟล์ {deleteFileIndex.name} หรือไม่?
            </AlertDialogBody>

              <Flex gap={2} className="p-4">
                <Button onClick={()=>setDeleteFileIndex({index:null,name:''})} ref={cancelRef} className="w-full" variant="outline">
                  <div className="font-normal">ยกเลิก</div>
                </Button>
                <Button colorScheme='red' onClick={()=>{confirmDelete()}} className="w-full">
                  <div className="font-normal">ลบ</div>
                </Button>
              </Flex>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default FormCoin;
