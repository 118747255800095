import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getHeaders } from "utils/common";

const ADMIN_PATH = `admin`;

export const managementApi = createApi({
  reducerPath: "managementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  tagTypes: ["MANAGEMENT"],
  endpoints: (builder) => ({
    adminList: builder.query({
      query: () => ({
        url: `/${ADMIN_PATH}/list`,
        method: "GET",
        headers: { ...getHeaders },
      }),
      providesTags: ["MANAGEMENT"],
    }),
  }),
  
});

export const { useAdminListQuery, usePrefetch } =
managementApi;
