import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getHeaders } from "utils/common";

const ADMIN_PATH = `admin`;
const HISTORY_PATH = `history`;
const CHARGE_PATH = `charge`;

export const chargeAPI = createApi({
  reducerPath: "insightAndHistoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  tagTypes: ["CHARGE"],
  endpoints: (builder) => ({
    getInsightAndHistory: builder.query({
      query: (arg) => ({
        url: `/${CHARGE_PATH}/${HISTORY_PATH}/${ADMIN_PATH}`,
        method: "GET",
        params: { ...arg },
        headers: { ...getHeaders },
      }),
      providesTags: ["CHARGE"],
    }),
  }),
  
});

export const { useGetInsightAndHistoryQuery, usePrefetch } =
chargeAPI;
