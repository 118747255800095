/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, SetStateAction } from "react";
import Select, { StylesConfig } from 'react-select';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/modal";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Divider,
  useToast,
} from "@chakra-ui/react";
import {Select as ChakraSelect} from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  getPlaceList
} from 'services/place'

import {
  updateEVStation,
  createEVStation
} from 'services/station'

const validate = (values: {
  evName: string;
  evId: string;
  evModel: string;
  evType: string;
  evSerial: string;
  placeStationId: string;
}) => {
  const errors: any = {};
  const inputStringPattern = /^[0-9a-zA-Zก-๙\s]+$/;
  if (values.evName) {
    if (!inputStringPattern.test(values.evName)) {
      errors.evName = "กรุณากรอกข้อมูลให้ถูกต้อง";
    }
  }
  if (!values.evName) {
    errors.evName = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }

  if (values.evId) {
    if (!inputStringPattern.test(values.evId)) {
      errors.evId = "กรุณากรอกข้อมูลให้ถูกต้อง";
    }
  }
  if (!values.evId) {
    errors.evId = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }

  if (!values.evModel) {
    errors.evModel = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }

  if (!values.evSerial) {
    errors.evSerial = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }

  if (!values.placeStationId) {
    errors.placeStationId = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }

  if (!values.evType) {
    errors.evType = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }
  return errors;
};

const customStyles: StylesConfig = {
  control: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    border: state.isFocused ? "1px solid #3182ce" : "1px solid #e9ecef",
    boxShadow: state.isFocused ? "0px 0px 0px 1px #3182ce" : "none",
    height: "40px",
    paddingLeft: "8px",
    paddingRight: "8px"
  }),
  placeholder: (provided: Record<string, unknown>) => ({
    ...provided,
    color: '#a2b0c1'
  }),
};

const ModalUI = (props: {
  title?: string;
  body?: string;
  fullName?: string;
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  station?: any;
  EVTypes?: any;
}) => {
  
  const [defaultStation, setDefaultStation] = useState(null)
  const toast = useToast();
  const { isOpen, onClose, onConfirm, title, station, EVTypes } = props;
  const [places, setPlaces] = useState([])
  const formik = useFormik({
    initialValues: {
      evType: "",
      evName: "",
      evId: "",
      evModel: "",
      evSerial: "",
      placeStationId: "",
    },
    enableReinitialize: true,
    validate,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });


  const fetchPlaces = async () => {
    try {
      const result = await getPlaceList()
      if (result.status === 200) {
        const optionPlace: SetStateAction<any[]> = []
        result.data.forEach((val: { id: any; stationName: any; }) => {
          optionPlace.push({value:val.id,label:val.stationName})
        });
        setPlaces(optionPlace)


      }
    } catch (error) {
      console.log('error :>> ', error)
    }
  }

  useEffect(() => {
    fetchPlaces()
  }, [])
  useEffect(() => {
    if(station){
      formik.setFieldValue('evName', station.evName);
      formik.setFieldValue('evId', station.id);
      formik.setFieldValue('evModel', station.evModel);
      formik.setFieldValue('evType', station.evType);
      formik.setFieldValue('evSerial', station.evSerial);
      formik.setFieldValue('placeStationId', station.placeStationId);
      setDefaultStation({label: station.placeStation.stationName, value: station.placeStationId})
    }
  }, [station])


  const handleSubmit = async (values: any) => {
    formik.setSubmitting(false);
    const payload = values;
    let result
    if(station){
      delete payload.evId;
      result = await updateEVStation(payload,station.id)
    }else{
      result = await createEVStation(payload)
    }
    formik.setSubmitting(true);
    if(result && result.status === 200){
      formik.resetForm();
      setDefaultStation(null);
      onConfirm();
    }else if(result.status === 401){
      window.location.replace(`/auth/sign-in`);
    } else {
      toast({
        position: 'top',
        title: 'Error',
        description: result.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  };

  const closeForm = () => {
    formik.resetForm();
    setDefaultStation(null);
    onClose();
  };

  const changeEvType = async (event: any) => {
    formik.setFieldValue("evType", event.target.value);
  };

  const changeEvPlace = async (data: { value: any; }) => {
    formik.setFieldValue("placeStationId", data ? data.value : null);
  };
  

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={closeForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div className="text-black mt-4 text-xl font-normal leading-7">
              {title}
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <FormControl
                isInvalid={
                  formik.errors.evName && formik.touched.evName
                }
              >
                <FormLabel htmlFor="evName">
                ชื่อเครื่องชาร์จ<span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  placeholder="ระบุชื่อเครื่องชาร์จ"
                  value={formik.values.evName}
                  id="evName"
                  variant="outline"
                  maxLength={100}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.evName}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={
                  formik.errors.evId && formik.touched.evId
                }
                className="mt-2"
              >
                <FormLabel htmlFor="evId">
                ID เครื่อง<span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  placeholder="ระบุ ID เครื่อง"
                  value={formik.values.evId}
                  id="evId"
                  variant="outline"
                  maxLength={100}
                  onChange={formik.handleChange}
                  disabled={station ? true : false}
                />
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.evId}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  formik.errors.evModel && formik.touched.evModel
                }
                className="mt-2"
              >
                <FormLabel htmlFor="evModel">
                  Model เครื่อง<span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  placeholder="เลือก Model เครื่อง"
                  value={formik.values.evModel}
                  id="evModel"
                  variant="outline"
                  maxLength={100}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.evModel}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                className="mt-2 mb-3"
                isInvalid={formik.errors.evType && formik.touched.evType}
              >
                <FormLabel htmlFor="evType">
                 ประเภทเครื่อง<span className="text-red-500">*</span>
                </FormLabel>
                <ChakraSelect
                  id="evType"
                  placeholder="ระบุประเภทเครื่อง"
                  value={formik.values.evType}
                  onChange={changeEvType}
                >
                  {EVTypes.map((val: { value:string;text:string }) => <option key={'evtype_'+val.value} value={val.value} >{val.text}</option>)}
                </ChakraSelect>
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.evType}
                </FormErrorMessage>
              </FormControl>


              <FormControl
                isInvalid={
                  formik.errors.evSerial && formik.touched.evSerial
                }
                className="mt-2"
              >
                <FormLabel htmlFor="evSerial">
                Serial เครื่อง<span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  placeholder="ระบุ Serial เครื่อง"
                  value={formik.values.evSerial}
                  id="evSerial"
                  variant="outline"
                  maxLength={100}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.evSerial}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                className="mt-2 mb-3"
                isInvalid={formik.errors.placeStationId && formik.touched.placeStationId}
              >
                <FormLabel htmlFor="placeStationId">
                 ระบุสถานีชาร์จ<span className="text-red-500">*</span>
                </FormLabel>
                {((station && defaultStation) || !station) && <Select
                  placeholder="เลือกสถานีชาร์จ"
                  id="placeStationId"
                  required
                  styles={customStyles}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="placeStationId"
                  defaultValue={station ? defaultStation : null}
                  options={places}
                  onChange={changeEvPlace}
                /> }
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.placeStationId}
                </FormErrorMessage>
              </FormControl>

            </ModalBody>

            <ModalFooter>
              <button
                // onClick={() => formik.handleSubmit()}
                type="submit"
                className="linear mt-[1vh] h-[44px] w-full rounded-xl bg-[#1D4289] text-base font-medium text-white transition duration-200"
              >
                ยืนยัน
              </button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalUI;
