import React from "react";
import Card from "components/card";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

type RowObj = {
  index?: number;
  user_name: string;
  role: string;
  status?: string;
};

function CheckTable(props: { tableData: any }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("index", {
      id: "index",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">#</p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="ml-1 text-sm text-navy-700 dark:text-white">
            {info.getValue() + "."}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("user_name", {
      id: "user_name",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          อีเมลล์
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("role", {
      id: "role",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          บทบาท
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="ml-3 text-sm text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    // columnHelper.accessor("status", {
    //   id: "status",
    //   header: () => (
    //     <p className="text-sm text-gray-600 dark:text-white">
    //       การจัดการ
    //     </p>
    //   ),
    //   cell: (info: any) => (
    //     <div className="flex items-center">
    //       <p className="ml-3 text-sm text-navy-700 dark:text-white">
    //         -
    //       </p>
    //     </div>
    //   ),
    // }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl text-navy-700 dark:text-white">
        รายชื่อสมาชิก
        </div>

        {/* <CardMenu /> */}
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 bg-[#F9FAFB] pb-2 pl-4 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0, 5)
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px]  border-b-[1px] border-gray-200  py-3  pl-4 pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default CheckTable;
const columnHelper = createColumnHelper<RowObj>();
