import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useLoginAdminMutation } from "redux/services/admin/adminApi";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function SignIn() {
  const location = useLocation();
  const [loginAdmin, responseLoginAdmin] = useLoginAdminMutation();
  const [getUser, _setUser] = useState(localStorage.getItem("token"));
  const [getError, setError] = useState("");
  const onSubmit = (e: any) => {
    e.preventDefault();
    const { email, password } = e.target.elements;
    let payload = {
      userName: email.value,
      password: password.value,
    };
    loginAdmin(payload);
  };

  useEffect(() => {
    if (responseLoginAdmin.isError) {
      const res = responseLoginAdmin.error as any;
      setError(res.data.message);
    } else if (responseLoginAdmin.isSuccess) {
      localStorage.setItem("token", responseLoginAdmin.data);
      window.location.replace(`/admin/dashboard`);
    }
  }, [responseLoginAdmin]);

  useEffect(() => {
    if (getUser) {
      window.location.replace(`/admin/dashboard`);
    }
  }, [getUser]);

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="mb-[5vh] text-4xl  text-navy-700 dark:text-white">
          <svg
            width="134"
            height="32"
            viewBox="0 0 134 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M72.0855 30.3765V1.29477C72.0855 0.576575 71.5089 0 70.7905 0H66.7436C66.0253 0 65.4486 0.576575 65.4486 1.29477V30.3765C65.4486 31.0947 66.0253 31.6713 66.7436 31.6713H70.7905C71.5089 31.6713 72.0855 31.0947 72.0855 30.3765Z"
              fill="#1D4289"
            />
            <path
              d="M120.74 16.1441L114.356 10.3986C114.113 10.1761 113.971 9.86249 113.971 9.52868V1.16327C113.971 0.515882 113.445 0 112.808 0H108.508C107.86 0 107.344 0.525997 107.344 1.16327V9.52868C107.344 9.86249 107.486 10.1761 107.729 10.3986L116.035 17.8739C116.248 18.0661 116.531 18.1773 116.814 18.1773H123.148L120.74 16.1441Z"
              fill="#1D4289"
            />
            <path
              d="M93.4231 30.5383V13.7265C93.4231 13.4231 93.3017 13.1399 93.0892 12.9274L88.7186 8.55758C88.0103 7.84951 86.7963 8.35528 86.7963 9.3567V30.5484C86.7963 31.1756 87.3021 31.6813 87.9294 31.6813H92.3001C92.9172 31.6712 93.4231 31.1655 93.4231 30.5383Z"
              fill="#1D4289"
            />
            <path
              d="M79.1475 0.0101318H101.072C101.699 0.0101318 102.205 0.5159 102.205 1.14305V5.5129C102.205 6.14006 101.699 6.64583 101.072 6.64583H79.1475C78.5203 6.64583 78.0144 6.14006 78.0144 5.5129V1.14305C78.0144 0.505785 78.5203 0.0101318 79.1475 0.0101318Z"
              fill="#1D4289"
            />
            <path
              d="M28.0249 5.35215V1.28577C28.0249 0.577694 27.4482 0.0112305 26.7501 0.0112305H6.62682H1.27478C0.576685 0.0112305 0 0.577694 0 1.28577V6.63681V12.5341V17.8851C0 18.5932 0.576685 19.1597 1.27478 19.1597H6.62682H20.0019C20.7101 19.1597 21.2767 18.5831 21.2767 17.8851V13.8086C21.2767 13.1005 20.7 12.5341 20.0019 12.5341H7.9016C7.19339 12.5341 6.62682 11.9575 6.62682 11.2595V7.92146C6.62682 7.21339 7.20351 6.64693 7.9016 6.64693H26.74C27.4482 6.63681 28.0249 6.06023 28.0249 5.35215Z"
              fill="#1D4289"
            />
            <path
              d="M0 30.3969C0 31.1049 0.576685 31.6714 1.27478 31.6714H6.62682H26.74C27.4482 31.6714 28.0148 31.0948 28.0148 30.3969V26.3204C28.0148 25.6123 27.4381 25.0458 26.74 25.0458H6.91011C6.58635 25.0458 6.28284 24.9244 6.05014 24.712L2.15498 21.1211C1.32536 20.3725 0 20.9491 0 22.0618V25.0458V30.3969Z"
              fill="#1D4289"
            />
            <path
              d="M127.367 1.17338V9.5388C127.367 9.87261 127.225 10.1862 126.982 10.4087L122.52 14.4245C122.004 14.8898 122.004 15.6991 122.52 16.1543L124.777 18.1773H124.969L133.609 10.3986C133.852 10.1761 133.993 9.86249 133.993 9.52868V1.16327C133.993 0.515882 133.467 0 132.83 0H128.53C127.893 0.0101154 127.367 0.525999 127.367 1.17338Z"
              fill="#1D4289"
            />
            <path
              d="M117.745 20.7466V30.4271C117.745 31.1149 118.301 31.6713 118.989 31.6713H123.137C123.825 31.6713 124.382 31.1149 124.382 30.4271V20.7466C124.382 20.0588 123.825 19.5024 123.137 19.5024H118.989C118.301 19.5126 117.745 20.0588 117.745 20.7466Z"
              fill="#1D4289"
            />
            <path
              d="M58.7715 0.0101137H57.7091H57.699H55.0685H54.4716L50.1111 8.71946H55.8172L43.3325 23.9937L45.1232 11.9463H38.2941L39.8117 0H38.8202H36.6247H35.1274H35.1172H34.0549C32.2945 0 31.1513 1.86123 31.9505 3.43923L44.3138 30.7103C45.1941 32.4299 47.6526 32.4299 48.5226 30.7103L60.886 3.43923C61.6852 1.87134 60.542 0.0101137 58.7715 0.0101137Z"
              fill="#008C15"
            />
          </svg>
        </div>

        <h4 className="mb-2.5 text-4xl  text-navy-700 dark:text-white">
          เข้าสู่ระบบ
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          ระบุอีเมลและรหัสผ่านเพื่อเข้าสู่ระบบ
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        {/* Email */}
        <form onSubmit={onSubmit}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="อีเมล*"
            placeholder="ระบุอีเมล"
            id="email"
            type="text"
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="รหัสผ่าน*"
            placeholder="ระบุรหัสผ่าน"
            id="password"
            type="password"
          />
          {/* Checkbox */}
          <div className="flex items-center justify-between px-2">
            <div className="flex items-end">
              <p className="ml-2 text-sm font-medium text-red-700 dark:text-white">
                {getError}
              </p>
            </div>
          </div>

          {/* <div className="mb-4 flex items-center justify-between px-2">
            <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div>
            <a
              className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              href=" "
            >
              Forgot Password?
            </a>
          </div> */}
          <button
            className="linear mt-[5vh] w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            type="submit"
          >
            เข้าสู่ระบบ
          </button>
        </form>

        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
}
