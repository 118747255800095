import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
const path = `todos`;
export const todoApi = createApi({
  reducerPath: "todoApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_TEST}` }),
  tagTypes: ["TODO_RE"],
  endpoints: (builder) => ({
    getTodos: builder.query<any, string>({
      query: () => `${path}`,
      providesTags: ['TODO_RE'],
    }),
  }),
});

export const {
  useGetTodosQuery,
  usePrefetch,
} = todoApi;
