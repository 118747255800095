import axios from './axios'

export const getPlaceList = () =>
  axios
    .get('/station/place')
    .then((res: any) => {
      if (res.data.length > 0) {
        res.data.map((val: any) => val.googleMapLink = `https://www.google.co.th/maps/@${val.lat},${val.lng},15z`)
      }
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })

export const getPlaceByID = (id: string) =>
  axios
    .get(`/station/place/${id}`)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })

export const getMeterType = () =>
  axios
    .get('/station/meter-type')
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })

export const getProvinces = () =>
  axios
    .get('/station/provinces')
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })

export const createPlaceStation = (payload: any) =>
  axios
    .post('/station/place', payload)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })

export const updatePlaceStation = (payload: any, id: string) =>
  axios
    .patch(`/station/place/${id}`, payload)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })


export const deletePlace = (id: string) =>
  axios
    .delete(`/station/place/${id}`)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })