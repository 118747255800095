import axios from './axios'


export const createCoin = (bodyFormData: any) =>
  axios
    .post('/coin/campaign', bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })


export const getCoinList = () =>
  axios
    .get('/coin/campaign')
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })

export const getCoinByid = (id: string) =>
  axios
    .get(`/coin/campaign/${id}`)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      if (err.response) return err.response
    })  

export const updateCoin = (bodyFormData: any, id: string) =>
    axios
      .post(`/coin/campaign/${id}`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        if (err.response) return err.response
      })

export const deleteCode = (id: string) =>
      axios
        .delete(`/coin/file/${id}`)
        .then((res: any) => {
          return res
        })
        .catch((err: any) => {
          if (err.response) return err.response
        })

export const deleteCoin = (id: string) =>
        axios
          .delete(`/coin/campaign/${id}`)
          .then((res: any) => {
            return res
          })
          .catch((err: any) => {
            if (err.response) return err.response
          })
        