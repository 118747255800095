import TableStation from "./components/TableStation";
import { useEffect, useState, useRef } from "react";
import {
  getStationList,
  getStationByID,
  getEVType,
} from 'services/station'
import ModalStation from "./components/ModalStation"
import {
  useDisclosure,
  AlertDialog,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex, 
} from "@chakra-ui/react";
import { MdOutlineOfflinePin } from "react-icons/md";

const ManagementStation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [titlePopup, setTitlePopup] = useState("เพิ่มเครื่องชาร์จสำเร็จ")
  const { 
    isOpen: isOpenCreateModal, 
    onOpen: onOpenCreateModal, 
    onClose: onCloseCreateModal 
} = useDisclosure()
  const [stations, setStations] = useState([])
  const [EVTypes, setEVTypes] = useState([])
  const [station, setStation] = useState(null)
  const cancelRef = useRef()
  const fetchStation = async () => {
    try {
      const resultType = await getEVType()
      if (resultType.status === 200) {
        setEVTypes(resultType.data)
        const result = await getStationList()
        if (result.status === 200) {
          setStations(result.data.map((item: any, index: number) => ({
            index: index + 1,
            ...item,
            evType: resultType.data.find((val: { value: any; })=>val.value === item.evType).text,
          })))
        }
      }
    } catch (error) {
      console.log('error :>> ', error)
    }
  }
  useEffect(() => {
    fetchStation()
  }, [stations.length])

  const reloadData = () => {
    fetchStation()
  }
  const onEdit = async (id:string) => {
    try {
      const result = await getStationByID(id)
      if (result.status === 200) {
        setStation(result.data)
        onOpenCreateModal()
      }
    } catch (error) {
      console.log('error :>> ', error)
    }
  }

  const onConfirm = () => {
    onCloseCreateModal()
    setTitlePopup(station ? "แก้ไขเครื่องชาร์จสำเร็จ" : "เพิ่มเครื่องชาร์จสำเร็จ")
    fetchStation()
    onOpen()
  }

  const onCreate = () => {
    setStation(null)
    onOpenCreateModal()
  }

  return (
    <div>
      <ModalStation EVTypes={EVTypes} title={station ? "แก้ไขเครื่องชาร์จ" : "สร้างเครื่องชาร์จ"} isOpen={isOpenCreateModal} onClose={onCloseCreateModal} onConfirm={onConfirm} station={station}/>
      <div className="flex flex-row-reverse mt-2">
          <button onClick={()=>onCreate()} type="button" className="text-white bg-[#1D4289] hover:bg-blue-800 font-medium rounded-xl text-sm px-3 py-2.5 text-center inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 5V19M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            สร้างเครื่องชาร์จ
          </button>
      </div>
      <div className="flex w-full flex-col gap-5">
        <div className="md:grid-cols mt-5 grid h-full grid-cols-1 gap-5">
          {stations.length > 0 && <TableStation tableData={stations} reloadData={reloadData} onEdit={onEdit}/>}
        </div>
      </div>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent className="p-2">
            <AlertDialogHeader fontSize='x-large' fontWeight='normal' className="text-center">
              <div className="flex justify-center mb-4 mt-4">
                <MdOutlineOfflinePin size={40} className="bg-[#E0E5F2] rounded-full p-1"/>
                </div>
                {titlePopup}
            </AlertDialogHeader>

              <Flex className="p-4">
                <Button colorScheme='facebook' onClick={onClose} ref={cancelRef} className="w-full">
                  <div className="font-normal">ตกลง</div>
                </Button>
              </Flex>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default ManagementStation;
