import React from "react";

// Admin Imports
import MainDashboard from "views/admin/dashboard";
import Management from "views/admin/management";
import Coins from "views/admin/coin";
import Place from "views/admin/place";
import Station from "views/admin/station";
import CoinForm from "views/admin/coin/form";
import CoinDetail from "views/admin/coin/detail";
import Charge from "views/admin/charge";
import CoinIcon  from "components/icons/CoinIcon";
import StationIcon  from "components/icons/StationIcon";
import ThunderIcon from "components/icons/ThunderIcon";
// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";


const routes = [
  {
    name: "แดชบอร์ด",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "รายงานยอดขาย",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "charge",
    component: <Charge />,
  },
  {
    name: "จัดการคอยน์",
    layout: "/admin",
    path: "coins",
    icon: <CoinIcon />,
    component: <Coins />,
  },
  {
    name: "เพิ่มคอยน์",
    layout: "/admin",
    path: "coins/create",
    icon: <CoinIcon />,
    component: <CoinForm />,
    secondary: true
  },
  {
    name: "รหัสการใช้คอยน์",
    layout: "/admin",
    path: "coins/:id",
    icon: <CoinIcon />,
    component: <CoinDetail />,
    secondary: true
  },
  {
    name: "เพิ่มคอยน์",
    layout: "/admin",
    path: "coins/:id/edit",
    icon: <CoinIcon />,
    component: <CoinForm />,
    secondary: true
  },
  {
    name: "จัดการสถานีชาร์จ",
    layout: "/admin",
    path: "place",
    icon: <StationIcon />,
    component: <Place />,
  },
  {
    name: "จัดการเครื่องชาร์จ",
    layout: "/admin",
    path: "station",
    icon: <ThunderIcon />,
    component: <Station />,
  },
  {
    name: "จัดการสมาชิก",
    layout: "/admin",
    path: "management-user",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Management />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
