import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { useEffect } from "react";

const PlacesAutocomplete = (
  props: {
    placeSelect?: (placeID:string,lat:number,lng:number) => void;
    followPin?: boolean;
    removePin?: () => void;
    location?: string
  }
) => {
  const { placeSelect, followPin, removePin } = props;
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
    cache: 24 * 60 * 60,
  });
  const ref = useOnclickOutside(() => {
    // When the user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  useEffect(() => {
    if(props.location){
      setValue(props.location, false);
      clearSuggestions();
    }
  }, [clearSuggestions, props.location, setValue])

  const handleInput = (e: { target: { value: string; }; }) => {
    removePin()
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }: any) =>
    () => {
      // When the user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const placeID = results[0]['place_id']
        const { lat, lng } = getLatLng(results[0]);
        placeSelect(placeID,lat,lng)
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li className="w-full px-4 py-2 border-b border-gray-200 cursor-pointer" key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref} className="mb-2 absolute z-10 top-10 w-[85%] ml-2">
      <input
        type="search"
        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        value={followPin ? 'Following Map' : value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Search Google Maps"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul className="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">{renderSuggestions()}</ul>}
    </div>
  );
};

export default PlacesAutocomplete;