import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getHeaders } from "utils/common";

const DASHBOARD_PATH = `dashboard`;
const SUMMARY_PATH = `summary-charge-paid`;
export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  tagTypes: ["DASHBOARD"],
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: (arg) => ({
        url: `/${DASHBOARD_PATH}/${SUMMARY_PATH}`,
        method: "GET",
        params: { ...arg },
        headers: { ...getHeaders },
      }),
      providesTags: ["DASHBOARD"],
    }),
  }),
});

export const { useGetDashboardQuery, usePrefetch } = dashboardApi;
