import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getHeaders } from "utils/common";

const CHARGE = `charge`;

export const reportProblemApi = createApi({
  reducerPath: "reportProblemApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  tagTypes: ["REPORTPROBLEM"],
  endpoints: (builder) => ({
    reportProblem: builder.query({
      query: (orderId) => ({
        url: `/${CHARGE}/report-problem/${orderId}`,
        method: "PATCH",
        headers: { ...getHeaders },
      }),
      providesTags: ["REPORTPROBLEM"],
    }),
  }),

});

export const { useReportProblemQuery, usePrefetch } =
  reportProblemApi;
