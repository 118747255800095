import numeral from "numeral";

// interface IToCurrency {
//   val?: number;
//   format?: string;
//   currency?: string;
// }

export const dateToUnix = (date: any) =>
  Math.floor(new Date(date).getTime() / 1000);
export const getBearerToken = () => `Bearer ${localStorage.getItem("token")}`;
export const getHeaders = {
  "Content-type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
export const clearToken = () => localStorage.removeItem("token");
export type ErrorType = { status: number; data: unknown };
export type HeatmapData = { x: string; y: string; value: number }[];

const colors = [
  // "bg-[#EAECF0] dark:bg-[#70a4fa] text-[#33373D]", //จองแท่นชาร์จ
  // "bg-[#F6F9FF] dark:bg-[#F6F9FF] text-[#1D4289]", //กำลังชาร์จ
  // "bg-[#EFFDF4] dark:bg-[#EFFDF4] text-[#35784D]", // ชาร์จสำเร็จ
  // "bg-[#FFF1F3] dark:bg-[#FFF1F3] text-[#EB001B]", //ยกเลิก
  "text-[#33373D]", //จองแท่นชาร์จ
  "text-[#1D4289]", //กำลังชาร์จ
  "text-[#35784D]", // ชาร์จสำเร็จ
  "text-[#EB001B]", //ยกเลิก
];

const formatCell = (cell: any, value: any) => {
  let colorClass;
  if (value.includes("ชาร์จสำเร็จ")) {
    colorClass = colors[2].split(" ");
  } else if (value.includes("ยกเลิกการจอง")) {
    colorClass = colors[3].split(" ");
  } else if (value.includes("กำลังชาร์จ")) {
    colorClass = colors[1].split(" ");
  } else if (value.includes("จองแท่นชาร์จ")) {
    colorClass = colors[0].split(" ");
  }

  colorClass?.forEach((className) => {
    cell.classList.add(className);
  });
};


export const coloumsCharge = [
  { label: "#", field: "index", sort: false, width: 50 },
  { label: "ID", field: "id", sort: true, width: 200 },
  { label: "ชื่อ นามสกุลลูกค้า", field: "fullName", sort: true, width: 200 },
  { label: "ช่องทาง", field: "channel", sort: true, width: 80 },
  { label: "สถานีชาร์จ", field: "hotelName", sort: true, width: 350 },
  {
    label: "สถานะ",
    field: "statusCharge",
    sort: true,
    width: 150,
    format: formatCell,
  },
  { label: "การชำระเงิน", field: "statusPayment", sort: true, width: 150 },
  { label: "ค่าชาร์จ (บาท)", field: "totalPrice", sort: true, width: 150 },
  { label: "ค่าปรับ (บาท)", field: "totalFine", sort: true, width: 150 },
  { label: "ชำระด้วยเงิน", field: "totalPay", sort: true, width: 150 },
  { label: "ชำระด้วยคอยน์", field: "useCoin", sort: true, width: 150 },
  { label: "ช่องทางที่ชำระ", field: "paymentMethod", sort: true, width: 200 },
  { label: "เวลาชำระเงิน", field: "paidDate", sort: true, width: 200 },
  { label: "จำนวนที่ชาร์จ (นาที)", field: "useDate", sort: true, width: 150 },
  { label: "พลังงานไฟฟ้า (kWh)", field: "useMeter", sort: true, width: 150 },
  {
    label: "จัดการ",
    sort: false,
    field: "",
    width: 150,
    fixed: 'right',
  },
];

export const columsDashboard = [
  { label: "#", field: "index", sort: false, width: 50 },
  {
    label: "ชื่อสถานีชาร์จ",
    field: "hotelName",
    sort: true,
    width: 300,
  },
  {
    label: "รายได้ (บาท)",
    field: "totalPaid",
    sort: false,
    width: 200,
  },
  {
    label: "จำนวนการชาร์จ (ครั้ง)",
    field: "totalListCharge",
    sort: true,
    width: 200,
  },
  {
    label: "เวลาชาร์จ (ชั่วโมง)",
    field: "totalHourCharge",
    sort: true,
    width: 200,
  },
  {
    label: "พลังงานไฟฟ้าที่ใช้ (kWh)",
    field: "totalUseMeter",
    sort: true,
    width: 200,
  },
];

export const toCurrency = (val?: number, format = "0,0") => {
  if (val || val === 0) {
    return numeral(val).format(format);
  } else {
    return "";
  }
};

export const toCurrencyV1 = (val?: number, format = "0,0.00") => {
  if (val || val === 0) {
    // eslint-disable-next-line no-useless-concat
    return numeral(val).format(format) + " " + "บาท";
  } else {
    return "";
  }
};

export const toCurrencyV2 = (val?: number, format = "0,0.00") => {
  if (val || val === 0) {
    // eslint-disable-next-line no-useless-concat
    return "฿" + " " + numeral(val).format(format);
  } else {
    return "";
  }
};

export const toCurrencyV3 = (val?: number, format = "0,0.00") => {
  if (val || val === 0) {
    return numeral(val).format(format);
  } else {
    return "";
  }
};
