import React from "react";
import Card from "components/card";
import dayjs from 'dayjs';
import "dayjs/locale/th";
import { MdOutlineEdit, MdOutlineRemoveRedEye, MdOutlineDownload, MdDeleteOutline, MdErrorOutline } from "react-icons/md";
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import { Link } from "react-router-dom";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getPaginationRowModel
} from "@tanstack/react-table";
import {
  Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Flex, 
  useToast
} from "@chakra-ui/react";
import {
  deleteCoin,
  getCoinByid
} from 'services/coin'

type RowObj = {
  id?: string;
  index?: number;
  name: string;
  status?: string;
  count?: number;
  startDate? : string;
  endDate? : string;
};

function TableCoin(props: { tableData: any, reloadData: any }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [coinId, setCoinId] = React.useState("")
  const [coinName, setCoinName] = React.useState("")
  const cancelRef = React.useRef()
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const uiStatus = (status : string) => {
    return status === 'Publish' ? 'เปิดใช้งาน' : 'ร่าง'
  }
  const styleStatus = (status : string) => {
    return status === 'Publish' ? 'bg-[#ECFDF3] text-[#027A48]' : 'bg-[#EEF4FF] text-[#3538CD]'
  }
  const convertDate = (date : string) =>{
    return dayjs(date).locale('th').format('DD MMM YYYY - HH:mm')
  }

  const handleRemove = (id: string) => {
    const coinName = data.find((val:any)=> val.id === id).name
    setCoinName(coinName)
    setCoinId(id)   
    onOpen()
  }

  const handleDownload = async (id: string) => {
    const response = await getCoinByid(id)
    if (response.status === 200 && response.data) {
      if(response.data.coins.length > 0){
        const coins = response.data.coins.map((val: { code: string; userId: string; value: string; })=>{
          const data:any = {}
          data.code = val.code
          data.value = val.value
          data.userId = val.userId
          return data
        })
        const ws = XLSX.utils.json_to_sheet(coins)
        const wb = { Sheets: {'Sheet 1': ws}, SheetNames: ['Sheet 1']}
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([excelBuffer], {
          type: "application/vnd.ms-excel;charset=utf-8"
        });
        FileSaver.saveAs(blob, `${response.data.name}.xlsx`);
      }
    }
  }
  
  const columns = [
    columnHelper.accessor("index", {
      id: "index",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">#</p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="ml-1 text-sm text-navy-700 dark:text-white">
            {info.getValue() + "."}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          ชื่อคอยน์
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          สถานะ
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <div className={`rounded-3xl px-3 py-1 ${styleStatus(info.getValue())}`}>
            <p className="text-sm">
              {uiStatus(info.getValue())}
            </p>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor("count", {
      id: "count",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          จำนวนคอยน์
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="ml-3 text-sm text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("startDate", {
      id: "startDate",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          วันและเวลาเริ่มต้น
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="ml-3 text-sm text-navy-700 dark:text-white">
            {convertDate(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("endDate", {
      id: "endDate",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          วันและเวลาสิ้นสุด
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="ml-3 text-sm text-navy-700 dark:text-white">
            {convertDate(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <div className="mr-4 flex items-center justify-center text-gray-600 dark:text-white gap-2">
            <div className="cursor-pointer" onClick={()=>{handleDownload(info.getValue())}}><MdOutlineDownload size={20}/></div>
            <div className="cursor-pointer"> <Link to={`/admin/coins/${info.getValue()}`}><MdOutlineRemoveRedEye size={20}/></Link></div>
            <div className="cursor-pointer"> <Link to={`/admin/coins/${info.getValue()}/edit`}><MdOutlineEdit size={20}/></Link></div>
            <div className="cursor-pointer" onClick={()=>{handleRemove(info.getValue())}}><MdDeleteOutline size={20}/></div>
          </div>
        </div>
      ),
    }),
  ];
  const data = tableData
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    autoResetAll: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  const confirmDelete = async () =>{
    const result = await deleteCoin(coinId)
    if(result && result.status === 200){
      onClose()
      props.reloadData()
    }else{
      toast({
        position: 'top',
        title: 'Error',
        description: result.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
   
  }
  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl text-navy-700 dark:text-white">
        รายชื่อคอยน์
        </div>
      </header>
      <div className="h-2" />
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 bg-[#F9FAFB] pb-2 pl-4 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px]  border-b-[1px] border-gray-200  py-3  pl-4 pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="flex items-center gap-2 justify-end mt-4 mb-4 text-sm">
          <span className="font-light">
                Rows per page:
          </span>
          <Select
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
            width={'80px'}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>

          <span className="flex items-center mx-3">


          {(table.getState().pagination.pageIndex * table.getState().pagination.pageSize) +1} - { (table.getState().pagination.pageIndex * table.getState().pagination.pageSize) + table.getRowModel()
                .rows.length } of {data.length}
          </span>

          <button
            className=" p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronDoubleLeftIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <button
            className="p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <button
            className="p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <button
            className="p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <ChevronDoubleRightIcon className="h-4 w-4" aria-hidden="true" />
          </button>
      </div>
      </div>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='x-large' fontWeight='normal' className="text-center">
              <div className="flex justify-center mb-2">
                <MdErrorOutline size={40} className="bg-red-100 rounded-full p-1 text-red-700"/>
                </div>
                ต้องการลบคอยน์
            </AlertDialogHeader>

            <AlertDialogBody className="text-center text-[#667085]">
              ยืนยันการลบคอยน์ {coinName} หรือไม่?
            </AlertDialogBody>

              <Flex gap={2} className="p-4">
                <Button onClick={onClose} ref={cancelRef} className="w-full" variant="outline">
                  <div className="font-normal">ยกเลิก</div>
                </Button>
                <Button colorScheme='red' onClick={()=>{confirmDelete()}} className="w-full">
                  <div className="font-normal">ลบ</div>
                </Button>
              </Flex>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
}

export default TableCoin;
const columnHelper = createColumnHelper<RowObj>();
