import { useState } from "react";
import { Renderer } from "./Renderer";
import { Tooltip } from "./Tooltip";
import Card from "components/card";

type HeatmapProps = {
  width: number;
  height: number;
  data: { x: string; y: string; value: number }[];
};

export type InteractionData = {
  xLabel: string;
  yLabel: string;
  xPos: number;
  yPos: number;
  value: number;
};

export const Heatmap = ({ width, height, data }: HeatmapProps) => {
  const [hoveredCell, setHoveredCell] = useState<InteractionData | null>(null);

  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl text-navy-700 dark:text-white">
        จำนวนการชาร์จในแต่ละช่วงเวลา
        </div>
      </header>
      <div style={{ position: "relative" }}>
        <Renderer
          width={width}
          height={height}
          data={data}
          setHoveredCell={setHoveredCell}
        />
        <Tooltip interactionData={hoveredCell} width={width} height={height} />
      </div>
    </Card>
  );
};
