import { InteractionData } from "./Heatmap";
import { css } from "@emotion/css";

type TooltipProps = {
  interactionData: InteractionData | null;
  width: number;
  height: number;
};

export const Tooltip = ({ interactionData, width, height }: TooltipProps) => {
  if (!interactionData) {
    return null;
  }

  return (
    // Wrapper div: a rect on top of the viz area
    <div
      style={{
        width,
        height,
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
      }}
    >
      {/* The actual box with dark background */}
      <div
        className={css`
          position: absolute;
          background-color: rgba(0, 0, 0, 0.8);
          border-radius: 4px;
          color: white;
          font-size: 12px;
          padding: 4px;
          margin-left: 15px;
          transform: translateY(-50%);

          &:after {
            content: "";
            position: absolute;
            border-width: 5px; /* Arrow width */
            left: -10px; /* Arrow width * 2 */
            top: 50%;
            transform: translateY(-50%);
            border-color: transparent black transparent transparent;
          }
        `}
        style={{
          position: "absolute",
          left: interactionData.xPos,
          top: interactionData.yPos,
        }}
      >
        {/* <TooltipRow label={"x"} value={interactionData.xLabel} />
        <TooltipRow label={"y"} value={interactionData.yLabel} /> */}
        <TooltipRow label={"value"} value={String(interactionData.value)} />
      </div>
    </div>
  );
};

type TooltipRowProps = {
  label: string;
  value: string;
};

const TooltipRow = ({ label, value }: TooltipRowProps) => (
  <div>
    <b>{label}</b>
    <span>: </span>
    <span>{value}</span>
  </div>
);
