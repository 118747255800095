/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 font-poppins text-[26px]  uppercase text-navy-700 dark:text-white">
          {/* Emetworks */}
          <svg
            width="109"
            height="26"
            viewBox="0 0 109 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M58.619 24.6809V1.052C58.619 0.468468 58.1504 0 57.5668 0H54.2786C53.695 0 53.2264 0.468468 53.2264 1.052V24.6809C53.2264 25.2644 53.695 25.7329 54.2786 25.7329H57.5668C58.1504 25.7329 58.619 25.2644 58.619 24.6809Z"
              fill="#1D4289"
            />
            <path
              d="M98.1505 13.1171L92.9635 8.44887C92.7662 8.26806 92.6511 8.01327 92.6511 7.74206V0.945154C92.6511 0.419154 92.2237 0 91.7058 0H88.2122C87.6861 0 87.2668 0.427373 87.2668 0.945154V7.74206C87.2668 8.01327 87.3819 8.26806 87.5792 8.44887L94.3281 14.5225C94.5007 14.6787 94.7309 14.7691 94.961 14.7691H100.107L98.1505 13.1171Z"
              fill="#1D4289"
            />
            <path
              d="M75.9557 24.8124V11.1528C75.9557 10.9063 75.8571 10.6762 75.6844 10.5036L72.1333 6.95306C71.5578 6.37775 70.5714 6.78869 70.5714 7.60234V24.8206C70.5714 25.3302 70.9824 25.7411 71.4921 25.7411H75.0433C75.5447 25.7329 75.9557 25.322 75.9557 24.8124Z"
              fill="#1D4289"
            />
            <path
              d="M64.357 0.00823975H82.1703C82.68 0.00823975 83.091 0.419176 83.091 0.928739V4.47924C83.091 4.9888 82.68 5.39974 82.1703 5.39974H64.357C63.8473 5.39974 63.4363 4.9888 63.4363 4.47924V0.928739C63.4363 0.410958 63.8473 0.00823975 64.357 0.00823975Z"
              fill="#1D4289"
            />
            <path
              d="M22.8197 4.34862V1.04469C22.8197 0.469377 22.3511 0.00912476 21.7839 0.00912476H5.43373H1.0852C0.517995 0.00912476 0.0494385 0.469377 0.0494385 1.04469V5.39241V10.1839V14.5317C0.0494385 15.107 0.517995 15.5672 1.0852 15.5672H5.43373H16.301C16.8764 15.5672 17.3367 15.0987 17.3367 14.5317V11.2195C17.3367 10.6442 16.8682 10.1839 16.301 10.1839H6.46949C5.89407 10.1839 5.43373 9.71547 5.43373 9.14837V6.43619C5.43373 5.86087 5.90229 5.40063 6.46949 5.40063H21.7757C22.3511 5.39241 22.8197 4.92394 22.8197 4.34862Z"
              fill="#1D4289"
            />
            <path
              d="M0.0494385 24.6974C0.0494385 25.2727 0.517995 25.7329 1.0852 25.7329H5.43373H21.7757C22.3511 25.7329 22.8114 25.2645 22.8114 24.6974V21.3852C22.8114 20.8099 22.3429 20.3497 21.7757 20.3497H5.6639C5.40085 20.3497 5.15424 20.251 4.96517 20.0784L1.80036 17.1608C1.1263 16.5526 0.0494385 17.0211 0.0494385 17.9251V20.3497V24.6974Z"
              fill="#1D4289"
            />
            <path
              d="M103.535 0.953374V7.75028C103.535 8.02149 103.42 8.27627 103.222 8.45709L99.5973 11.7199C99.178 12.098 99.178 12.7555 99.5973 13.1253L101.43 14.7691H101.587L108.607 8.44887C108.804 8.26806 108.919 8.01327 108.919 7.74206V0.945154C108.919 0.419154 108.492 0 107.974 0H104.48C103.962 0.00821874 103.535 0.427375 103.535 0.953374Z"
              fill="#1D4289"
            />
            <path
              d="M95.7172 16.8567V24.722C95.7172 25.2809 96.1693 25.733 96.7283 25.733H100.099C100.658 25.733 101.11 25.2809 101.11 24.722V16.8567C101.11 16.2978 100.658 15.8458 100.099 15.8458H96.7283C96.1693 15.854 95.7172 16.2978 95.7172 16.8567Z"
              fill="#1D4289"
            />
            <path
              d="M47.8013 0.00821737H46.9381H46.9299H44.7926H44.3076L40.7647 7.08456H45.4009L35.2571 19.4949L36.7121 9.70634H31.1634L32.3964 0H31.5909H29.807H28.5904H28.5822H27.7191C26.2888 0 25.3599 1.51225 26.0093 2.79437L36.0545 24.9521C36.7696 26.3493 38.7672 26.3493 39.4741 24.9521L49.5193 2.79437C50.1687 1.52047 49.2398 0.00821737 47.8013 0.00821737Z"
              fill="#008C15"
            />
          </svg>

          {/* <span className="font-medium">FREE</span> */}
        </div>
      </div>
      <div className="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes.filter((r) => r.layout !== "/auth" && !r.secondary)} />
      </ul>

      {/* Free Horizon Card */}
      {/* <div className="flex justify-center">
        <SidebarCard />
      </div> */}

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
