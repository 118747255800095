import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getHeaders } from "utils/common";


export const coinApi = createApi({
  reducerPath: "coinApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  tagTypes: ["COIN"],
  endpoints: (builder) => ({
    coinList: builder.query({
      query: () => ({
        url: `/coin/campaign`,
        method: "GET",
        headers: { ...getHeaders },
      }),
      providesTags: ["COIN"],
    }),
    coinDetail: builder.query({
      query: (id) => ({
        url: `/coin/campaign/${id}`,
        method: "GET",
        headers: { ...getHeaders },
      }),
      providesTags: (result, error, id) => [{ type: 'COIN', id }],
    }),
  }),
  
});

export const { useCoinListQuery, useCoinDetailQuery, usePrefetch } = coinApi;
