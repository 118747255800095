import React from "react";
import Card from "components/card";
import dayjs from 'dayjs';
import "dayjs/locale/th";
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getPaginationRowModel
} from "@tanstack/react-table";
import {
  Select
} from "@chakra-ui/react";

type RowObj = {
  index?: number;
  code?: string;
  userId?: string;
  updatedAt? : string;
};

function TableCode(props: { tableData: any; name: string }) {
  const { tableData, name } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  let defaultData = tableData;
  const convertDate = (date : string) =>{
    return dayjs(date).locale('th').format('DD MMM YYYY - HH:mm')
  }
  
  const columns = [
    columnHelper.accessor("index", {
      id: "index",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">#</p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="ml-1 text-sm text-navy-700 dark:text-white">
            {info.getValue() + "."}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("code", {
      id: "code",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          รหัสคอยน์ส่วนลด
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("userId", {
      id: "userId",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          การใช้งาน
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm text-navy-700 dark:text-white">
            {info.getValue() ? 'ใช้งานแล้ว' : '-'}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("updatedAt", {
      id: "updatedAt",
      header: () => (
        <p className="text-sm text-gray-600 dark:text-white">
          วันที่อัปโหลด
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="ml-3 text-sm text-navy-700 dark:text-white">
            {convertDate(info.getValue())}
          </p>
        </div>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl text-navy-700 dark:text-white">
        {name}
        </div>
      </header>
      <div className="h-2" />
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 bg-[#F9FAFB] pb-2 pl-4 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px]  border-b-[1px] border-gray-200  py-3  pl-4 pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="flex items-center gap-2 justify-end mt-4 mb-4 text-sm">
          <span className="font-light">
                Rows per page:
          </span>
          <Select
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
            width={'80px'}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>

          <span className="flex items-center mx-3">


          {(table.getState().pagination.pageIndex * table.getState().pagination.pageSize) +1} - { (table.getState().pagination.pageIndex * table.getState().pagination.pageSize) + table.getRowModel()
                .rows.length } of {data.length}
          </span>

          <button
            className=" p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronDoubleLeftIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <button
            className="p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <button
            className="p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <button
            className="p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <ChevronDoubleRightIcon className="h-4 w-4" aria-hidden="true" />
          </button>
      </div>
      </div>
    </Card>
  );
}

export default TableCode;
const columnHelper = createColumnHelper<RowObj>();
