import TableCoin from "./components/TableCoin";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getCoinList
} from 'services/coin'

const ManagementCoin = () => {
  const [coins, setCoins] = useState([])
  const fetchCoin = async () => {
    try {
      const result = await getCoinList()
      if (result.status === 200) {
        setCoins(result.data.map((item: any, index: number) => ({
          index: index + 1,
          ...item,
        })))
      }
    } catch (error) {
      console.log('error :>> ', error)
    }
  }
  useEffect(() => {
    fetchCoin()
  }, [coins.length])
  const reloadData = () => {
    fetchCoin()
  }
  return (
    <div>
      <div className="flex flex-row-reverse mt-2">
        <Link to="/admin/coins/create">
          <button type="button" className="text-white bg-[#1D4289] hover:bg-blue-800 font-medium rounded-xl text-sm px-3 py-2.5 text-center inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 5V19M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            เพิ่มคอยน์
          </button>
        </Link>
      </div>
      <div className="flex w-full flex-col gap-5">
        <div className="md:grid-cols mt-5 grid h-full grid-cols-1 gap-5">
          {coins.length > 0 && <TableCoin tableData={coins} reloadData={reloadData}/>}
        </div>
      </div>
    </div>
  );
};

export default ManagementCoin;
