import PieChartCard from "views/admin/dashboard/components/PieChartCard";

import Widget from "components/widget/Widget";
import Card from "components/card";
import StationTopChart from "views/admin/dashboard/components/StationTopChart";
import { Heatmap } from "views/admin/dashboard/components/heatmap/Heatmap";
import {} from "redux/store";
import React, { useEffect, useState } from "react";
import Datepicker, { DatepickerType } from "react-tailwindcss-datepicker";
import {
  ErrorType,
  clearToken,
  columsDashboard,
  dateToUnix,
  toCurrency,
  toCurrencyV2,
  toCurrencyV3,
} from "utils/common";
import { CSVLink } from "react-csv";
import { Datatable, initTE } from "tw-elements";
import { useGetDashboardQuery } from "redux/services/dashboard/dashboardApi";
import dayjs from "dayjs";
import "dayjs/locale/th";
initTE({ Datatable });

const Dashboard = () => {
  const [getDate, setDate] = useState({
    startDate: dayjs().add(-1, "month").format(),
    endDate: dayjs().format(),
  });

  const [isSession, setSession] = useState(0);
  const [getTopStation, setTopStation] = useState([]);
  const [getPieChart, setPieChart] = useState({});
  const [getHeatMap, setHeatMap] = useState([]);
  const [getHeaders] = useState([
    { label: "ชื่อสถานีชาร์จ", key: "hotelName" },
    { label: "รายได้ (บาท)", key: "totalPaid" },
    { label: "จำนวนการชาร์จ (ครั้ง)", key: "totalListCharge" },
    { label: "เวลาชาร์จ (ชั่วโมง)", key: "totalHourCharge" },
    { label: "พลังงานไฟฟ้าที่ใช้ (kWh)", key: "totalUseMeter" },
  ]);
  const handleValueChange = (newValue: any) => {
    setDate(newValue);
    setSession(0);
  };

  const {
    data: dataRes,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useGetDashboardQuery({
    startDate: dateToUnix(dayjs(getDate.startDate).startOf("day")),
    endDate: dateToUnix(dayjs(getDate.endDate).endOf("day")),
  });

  useEffect(() => {
    const err = error as ErrorType;
    if (err && err.status === 401) {
      clearToken();
      window.location.reload();
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("datatable").innerHTML = "";
      let period: any = [];
      const weekEng = Object.keys(dataRes.trafficPeriod);
      const timeSpaces = Object.keys(dataRes.trafficPeriod.Mon);
      const week = ["จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส.", "อา."].reverse();
      for (let x = 0; x < 24; x++) {
        for (let y = 0; y < 7; y++) {
          period.push({
            x: timeSpaces[x],
            y: week[y],
            value: dataRes.trafficPeriod[weekEng[y]][timeSpaces[x]],
          });
        }
      }
      setTopStation(dataRes?.datas);
      setPieChart(dataRes?.pieChart);
      setHeatMap(period);
      const payload = {
        columns: columsDashboard,
        rows: dataRes?.datas.map((r: any, i: number) => ({
          ...r,
          index: `${i + 1}.`,
        })),
      };
      new Datatable(document.getElementById("datatable"), payload, {
        loading: isLoading,
      });
    }
  }, [
    isSession,
    isLoading,
    isSuccess,
    dataRes?.datas,
    dataRes?.pieChart,
    dataRes?.trafficPeriod,
  ]);

  return (
    <div>
      {/* Card widget */}
      <div className="... relative w-full" style={{ textAlignLast: "end" }}>
        <div className="... inline-flex justify-end">
          <div className="linear relative mr-4 mt-4 w-[330px]">
            {!!getDate.endDate && (
              <div
                style={{
                  position: "absolute",
                  left: "7px",
                  top: "7px",
                  zIndex: 1,
                }}
                hidden={!!!getDate.endDate}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
                    stroke="#5F5F5F"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
            <Datepicker
              i18n={`th`}
              value={getDate}
              onChange={handleValueChange}
            />
          </div>
          <button className="linear mt-4 flex w-6/12 items-center justify-center rounded-xl bg-[#1D4289] px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            <CSVLink
              headers={getHeaders}
              data={getTopStation}
              filename={`รายงานสถานีชาร์จ-${dayjs(getDate.startDate).format(
                "DD-MM-YYYY"
              )}-to-${dayjs(getDate.endDate).format("DD-MM-YYYY")}`}
              className="linear flex"
            >
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "10px" }}
              >
                <path
                  d="M13.3334 12.3334L10 9.00003M10 9.00003L6.66669 12.3334M10 9.00003V16.5M16.9917 14.325C17.8045 13.8819 18.4466 13.1808 18.8166 12.3322C19.1866 11.4837 19.2635 10.5361 19.0352 9.63894C18.8069 8.74182 18.2863 7.94629 17.5556 7.3779C16.8249 6.80951 15.9257 6.50064 15 6.50003H13.95C13.6978 5.5244 13.2277 4.61864 12.575 3.85085C11.9223 3.08307 11.1041 2.47324 10.1818 2.0672C9.25949 1.66116 8.25715 1.46949 7.25013 1.5066C6.2431 1.5437 5.25758 1.80861 4.36768 2.28142C3.47777 2.75422 2.70662 3.42261 2.11221 4.23635C1.5178 5.05008 1.1156 5.98797 0.935844 6.97952C0.756086 7.97107 0.803449 8.99047 1.07437 9.96108C1.3453 10.9317 1.83273 11.8282 2.50003 12.5834"
                  stroke="white"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              ดาวน์โหลดรายงาน
            </CSVLink>
          </button>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-4">
        <Widget
          icon={
            <svg
              className="h-7 w-7"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6733 15.6398C13.567 14.8325 12.5681 13.9978 12.5681 12.6978C12.5681 11.2062 13.9502 10.1662 16.2628 10.1662C18.2059 10.1662 19.1775 10.9052 19.5333 12.082C19.6975 12.6294 20.1491 13.0399 20.7238 13.0399H21.1343C22.0374 13.0399 22.6806 12.1504 22.3659 11.302C21.7911 9.6873 20.4501 8.34626 18.3154 7.82627V6.88207C18.3154 5.7463 17.3986 4.82947 16.2628 4.82947C15.127 4.82947 14.2102 5.7463 14.2102 6.88207V7.78522C11.5555 8.35995 9.42078 10.0841 9.42078 12.7252C9.42078 15.8862 12.0344 17.4598 15.8523 18.3767C19.2733 19.1977 19.9575 20.4019 19.9575 21.6745C19.9575 22.6187 19.287 24.1239 16.2628 24.1239C14.0049 24.1239 12.8418 23.3166 12.3902 22.1671C12.1849 21.6335 11.7197 21.2503 11.1586 21.2503H10.7755C9.85866 21.2503 9.21552 22.1808 9.55762 23.0292C10.3376 24.9313 12.1576 26.0534 14.2102 26.4913V27.4081C14.2102 28.5439 15.127 29.4607 16.2628 29.4607C17.3986 29.4607 18.3154 28.5439 18.3154 27.4081V26.5187C20.9838 26.0123 23.1048 24.466 23.1048 21.6608C23.1048 17.7746 19.7796 16.4472 16.6733 15.6398Z"
                fill="#1D4289"
              />
            </svg>
          }
          title={"รายได้"}
          subtitle={`${toCurrencyV2(dataRes?.headers.totalPaid)}`}
        />

        <Widget
          icon={
            <svg
              className="h-6 w-6"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.9992 16.9991V8.99951V16.9991Z" fill="#1D4289" />
              <path
                d="M11.9992 8.99951C13.656 8.99951 14.9991 7.65643 14.9991 5.99966C14.9991 4.34289 13.656 2.99982 11.9992 2.99982C10.3425 2.99982 8.9994 4.34289 8.9994 5.99966C8.9994 7.65643 10.3425 8.99951 11.9992 8.99951Z"
                fill="#1D4289"
              />
              <path
                d="M15.999 13.3737C19.5307 14.0681 21.9987 15.6539 21.9987 17.4991C21.9987 19.9842 17.5218 21.9989 11.9992 21.9989C6.47668 21.9989 1.99976 19.9842 1.99976 17.4991C1.99976 15.6539 4.46782 14.0681 7.99945 13.3737M11.9992 16.9991V8.99951M11.9992 8.99951C13.656 8.99951 14.9991 7.65643 14.9991 5.99966C14.9991 4.34289 13.656 2.99982 11.9992 2.99982C10.3425 2.99982 8.9994 4.34289 8.9994 5.99966C8.9994 7.65643 10.3425 8.99951 11.9992 8.99951Z"
                stroke="#1D4289"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
          title={"จำนวนการชาร์จ (ครั้ง)"}
          subtitle={`${toCurrency(dataRes?.headers?.totalListCharge)}`}
        />

        <Widget
          icon={
            <svg
              className="h-7 w-7"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.75756 13.5893H10.0312C11.0849 13.5893 11.947 14.4514 11.947 15.5051V25.0839C11.947 26.1376 11.0849 26.9997 10.0312 26.9997H9.75756C8.70389 26.9997 7.8418 26.1376 7.8418 25.0839V15.5051C7.8418 14.4514 8.70389 13.5893 9.75756 13.5893ZM17.4206 7.84204C18.4743 7.84204 19.3364 8.70413 19.3364 9.75781V25.0839C19.3364 26.1376 18.4743 26.9997 17.4206 26.9997C16.3669 26.9997 15.5049 26.1376 15.5049 25.0839V9.75781C15.5049 8.70413 16.3669 7.84204 17.4206 7.84204ZM25.0837 18.7893C26.1373 18.7893 26.9994 19.6514 26.9994 20.705V25.0839C26.9994 26.1376 26.1373 26.9997 25.0837 26.9997C24.03 26.9997 23.1679 26.1376 23.1679 25.0839V20.705C23.1679 19.6514 24.03 18.7893 25.0837 18.7893Z"
                fill="#1D4289"
              />
            </svg>
          }
          title={"เวลาการชาร์จ (ชั่วโมง)"}
          subtitle={`${toCurrency(dataRes?.headers?.totalHourCharge)}`}
        />
        <Widget
          icon={
            <svg
              className="h-6 w-6"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 2L4.09344 12.6879C3.74463 13.1064 3.57023 13.3157 3.56756 13.4925C3.56524 13.6461 3.63372 13.7923 3.75324 13.8889C3.89073 14 4.16316 14 4.70802 14H12L11 22L19.9065 11.3121C20.2553 10.8936 20.4297 10.6843 20.4324 10.5075C20.4347 10.3539 20.3663 10.2077 20.2467 10.1111C20.1092 10 19.8368 10 19.292 10H12L13 2Z"
                fill="#1D4289"
                stroke="#1D4289"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
          title={"พลังงานไฟฟ้าที่ใช้ (kWh)"}
          subtitle={`${toCurrencyV3(dataRes?.headers?.totalUseMeter / 1000)}`}
        />
      </div>

      {/* Traffic chart & Pie Chart */}

      <div className="mt-5 grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
        <PieChartCard data={getPieChart} />
        <StationTopChart tableData={getTopStation} />
      </div>

      {/* Tables & Charts */}

      <div className="xl:grid-cols mb-5 mt-5 grid grid-cols-1 gap-5">
        <Heatmap data={getHeatMap} width={1250} height={400} />
        <Card extra={"w-full h-full sm:overflow-auto px-6"}>
          <header className="relative flex items-center justify-between pt-4">
            <div className="text-xl text-navy-700 dark:text-white">
              รายงานสถานีชาร์จ
            </div>
          </header>
          <div id="datatable"></div>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
