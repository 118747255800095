import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import Card from "components/card";
import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";

const PieChartCard = (props: any) => {
  const { data } = props;
  const [getWalkin, setW] = useState(data.walkin);
  const [getBooking, setB] = useState(data.booking);
  useEffect(() => {
    setW(data.walkin);
    setB(data.booking);
  }, [data]);
  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg text-navy-700 dark:text-white">
            สถานะการชาร์จ
          </h4>
        </div>

        {/* <div className="mb-6 flex items-center justify-center">
          <select className="mb-3 mr-2 flex items-center justify-center text-sm text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="weekly">Weekly</option>
          </select>
        </div> */}
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        {(getBooking ||  getWalkin) && (
          <PieChart
            chartOptions={pieChartOptions}
            chartData={[getBooking, getWalkin]}
          />
        )}
      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-brand-500" />
            <p className="ml-1 text-sm font-normal text-gray-600">
              จองล่วงหน้า
            </p>
          </div>
          <p className="mt-px text-xl text-navy-700  dark:text-white">
            {(getBooking + getWalkin !== 0) ?  ((getBooking / (getBooking + getWalkin)) * 100).toFixed(2) : 0}%
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
            <p className="ml-1 text-sm font-normal text-gray-600">
              ชาร์จหน้างาน
            </p>
          </div>
          <p className="mt-px text-xl text-navy-700 dark:text-white">
            {(getBooking + getWalkin !== 0) ? ((getWalkin / (getWalkin + getBooking)) * 100).toFixed(2) : 0}%
          </p>
        </div>
      </div>
    </Card>
  );
};

export default PieChartCard;
