import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
const ADMIN_PATH = `admin`;
export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/${ADMIN_PATH}`,
  }),
  tagTypes: ["LOGIN"],
  endpoints: (builder) => ({
    loginAdmin: builder.mutation({
      query: (payload) => ({
        url: "/login",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),
  }),
});

export const { useLoginAdminMutation, usePrefetch } = loginApi;
