import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { ReactComponent as Icon } from "../../assets/svg/alert-circle.svg";

const ModalUI = (props: {
  title?: string;
  body?: string;
  fullName?: string;
  station?: string;
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}) => {
  const { isOpen, onClose, onConfirm, body = "Modal Body", title = "Modal Title", fullName = "-", station = "-" } = props;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='lg' >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon />
            <div className="mt-4 w-[465px] text-gray-900 text-2xl font-bold leading-7">
              {title}
            </div >
          </ModalHeader >
          {/* <ModalCloseButton /> */}
          < ModalBody >
            <div>
              <span className="text-gray-500 text-xl font-normal leading-loose">
                รายการจองนี้พบปัญหา กดยืนยันเพื่อยกเลิกรายการ
                <span className="text-gray-500 text-xl font-semibold leading-loose"> {fullName} <br />{station}</span> หรือไม่?
              </span>

            </div>
          </ModalBody >

          <ModalFooter>
            <button
              onClick={onClose}
              className="linear mt-[1vh] w-full rounded-xl bg-[#FFFFFF] h-[44px] text-base font-medium text-back transition duration-200 active:bg-[#FFFFFF] dark:bg-brand-400 dark:text-white dark:hover:bg-[#FFFFFF] dark:active:bg-brand-200 border border-[#D0D5DD]"
            >
              ยกเลิก
            </button>
            <div className="mr-[1vh]"></div>
            <button
              onClick={onConfirm}
              className="linear mt-[1vh] w-full rounded-xl bg-[#d92d20] h-[44px] text-base font-medium text-white transition duration-200 hover:bg-[#ff3b31] active:bg-[#ff5247] dark:bg-[#b71c1c] dark:text-white dark:hover:bg-[#ff4444] dark:active:bg-[#ff6e6e]"
            >
              ยืนยัน
            </button>
          </ModalFooter>
        </ModalContent >
      </Modal >
    </>
  );
};

export default ModalUI;
