import TablePlace from "./components/TablePlace";
import { useEffect, useState, useRef } from "react";
import {
  getPlaceList,
  getPlaceByID
} from 'services/place'
import {
  useDisclosure,
  AlertDialog,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex, 
} from "@chakra-ui/react";
import { MdOutlineOfflinePin } from "react-icons/md";
import ModalPlace from "./components/ModalPlace"

const ManagementCoin = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { 
    isOpen: isOpenCreateModal, 
    onOpen: onOpenCreateModal, 
    onClose: onCloseCreateModal 
} = useDisclosure()
  const [places, setPlaces] = useState([])
  const [place, setPlace] = useState(null)
  const [titlePopup, setTitlePopup] = useState("เพิ่มสถานีชาร์จสำเร็จ")
  const fetchPlace = async () => {
    try {
      const result = await getPlaceList()
      if (result.status === 200) {
        setPlaces(result.data.map((item: any, index: number) => ({
          index: index + 1,
          ...item,
        })))
      }
    } catch (error) {
      console.log('error :>> ', error)
    }
  }
  const cancelRef = useRef()

  useEffect(() => {
    fetchPlace()
  }, [places.length])

  const reloadData = () => {
    fetchPlace()
  }
  const onEdit = async (id:string) => {
    try {
      const result = await getPlaceByID(id)
      if (result.status === 200) {
        setPlace(result.data)
        onOpenCreateModal()
      }
    } catch (error) {
      console.log('error :>> ', error)
    }
  }

  const onConfirm = () => {
    onCloseCreateModal()
    setTitlePopup(place ? "แก้ไขสถานีชาร์จสำเร็จ" : "เพิ่มสถานีชาร์จสำเร็จ")
    fetchPlace()
    onOpen()
  }

  const onCreate = () => {
    setPlace(null)
    onOpenCreateModal()
  }
  
  return (
    <div>
      <ModalPlace title={place ? "แก้ไขสถานีชาร์จ" : "สร้างสถานทีชาร์จ"} isOpen={isOpenCreateModal} onClose={onCloseCreateModal} onConfirm={onConfirm} place={place}/>
      <div className="flex flex-row-reverse mt-2">
          <button onClick={()=>onCreate()} type="button" className="text-white bg-[#1D4289] hover:bg-blue-800 font-medium rounded-xl text-sm px-3 py-2.5 text-center inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 5V19M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            สร้างสถานีชาร์จ
          </button>
      </div>
      <div className="flex w-full flex-col gap-5">
        <div className="md:grid-cols mt-5 grid h-full grid-cols-1 gap-5">
          {places.length > 0 && <TablePlace tableData={places} reloadData={reloadData} onEdit={onEdit}/>}
        </div>
      </div>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent className="p-2">
            <AlertDialogHeader fontSize='x-large' fontWeight='normal' className="text-center">
              <div className="flex justify-center mb-4 mt-4">
                <MdOutlineOfflinePin size={40} className="bg-[#E0E5F2] rounded-full p-1"/>
                </div>
                {titlePopup}
            </AlertDialogHeader>

              <Flex className="p-4">
                <Button colorScheme='facebook' onClick={onClose} ref={cancelRef} className="w-full">
                  <div className="font-normal">ตกลง</div>
                </Button>
              </Flex>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default ManagementCoin;
