import CheckTable from "./components/CheckTable";
import { useAdminListQuery } from "redux/services/management/managementApi";
import { useMemo } from "react";

const ManagementUser = () => {
  const { data } = useAdminListQuery("");
  const table = useMemo(() => {
    return data?.map((item: any, index: number) => ({
      index: index + 1,
      ...item,
    }));
  }, [data]);
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="md:grid-cols mt-5 grid h-full grid-cols-1 gap-5">
        {table && <CheckTable tableData={table} />}
      </div>
    </div>
  );
};

export default ManagementUser;
