import axios from 'axios'

export interface ApiResponse<T> {
  statusCode: number
  message: string
  payload: T
}

const baseURL = process.env.REACT_APP_API_URL
const defaultOptions = {
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
}

const ins = axios.create(defaultOptions)

ins.interceptors.request.use((config:any) => {
  const accessToken: any = localStorage.getItem("token")
  config.headers = config.headers ?? {}
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
  }

  return config
})

export default ins
