import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/modal";
import {
  Divider,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getPaginationRowModel
} from "@tanstack/react-table";
import ChangeIcon from "components/icons/ChangeIcon";

type RowObj = {
  evName?: string;
  id?: string;
};

const ModalUI = (props: {
  title?: string;
  id?: string;
  isOpen: boolean;
  station: any;
  onClose?: () => void;
  onConfirm?: () => void;
}) => {
  const { isOpen, onClose, station } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const columns = [
    columnHelper.accessor("evName", {
      id: "evName",
      header: () => (
        <p className="flex-auto w-60 font-normal text-sm text-gray-600 text-start">ชื่อเครื่องชาร์จ</p>
      ),
      cell: (info: any) => (
        <div className="flex-auto flex items-center w-60">
          <p className="flex gap-2 text-sm text-navy-700 text-start">
          <ChangeIcon/>{info.getValue() || '-'}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="flex-auto w-20 font-normal text-sm text-gray-600 text-start">
          ID เครื่อง
        </p>
      ),
      cell: (info: any) => (
        <div className="flex-auto w-20 flex justify-start">
          <p className="text-sm text-navy-700">
            {info.getValue()}
          </p>
        </div>
      ),
    })
  ]
  const data = station.evStations
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    autoResetAll: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
         <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <div className="text-black mt-4 text-xl font-normal leading-7">
                {station.stationName}
              </div>
            </ModalHeader>
            <ModalCloseButton />
            <Divider />
            <ModalBody>
              <div className="mt-1 mb-2 text-xl text-[#344054]">ระบุเครื่องชาร์จ</div>
              <div className="mb-4 p-6 bg-white border border-gray-200 rounded-xl shadow">
                <div className="relative overflow-x-auto shadow-md rounded-lg">
                <table className="w-full">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className="!border-px !border-gray-400">
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              onClick={header.column.getToggleSortingHandler()}
                              className="cursor-pointer border-b-[1px] border-gray-200 bg-[#F9FAFB] pb-2 pl-4 pr-4 pt-4"
                            >
                              <div className="items-center justify-between text-xs text-gray-200">
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: "",
                                  desc: "",
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table
                      .getRowModel()
                      .rows
                      .map((row) => {
                        return (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td
                                  key={cell.id}
                                  className="min-w-[150px]  border-b-[1px] border-gray-200  py-3  pl-4 pr-4"
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
      </Modal>
    </>)
}


export default ModalUI;
const columnHelper = createColumnHelper<RowObj>();